// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCunMyrk3XUZ1_uo6sLYKExZ4Qj8r16YBU",
  authDomain: "zaferayan-com.firebaseapp.com",
  projectId: "zaferayan-com",
  storageBucket: "zaferayan-com.appspot.com",
  messagingSenderId: "484970108609",
  appId: "1:484970108609:web:6bc3553b4b9e52b90f2640",
  measurementId: "G-N909RK2F91",
};
export default firebaseConfig;
